<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMdrlsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="材料编号:">
              <el-input size="small" v-model.trim="searchForm.mdrl_no" clearable placeholder="请填写材料编号名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="材料名称:">
              <el-input size="small" v-model.trim="searchForm.mdrl_name" clearable placeholder="请填写材料名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="材料类型:">
              <el-select size="small" v-model="searchForm.mdrl_type" placeholder="请选择材料类型" clearable>
                <el-option v-for="item in mdrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="6">
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMdrlsNow()">查询</el-button>
              <el-button size="small" type="info" icon="el-icon-refresh" @click="getMdrlsNowO()" class="vg_ml_16 vg_mr_8">刷新</el-button>
              <el-link type="primary" @click="goMdrl" class="vg_cursor"><i class="el-icon-edit-outline"></i>材料信息表管理</el-link>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="vd_mar15">
      <div><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" @row-dblclick="confirmIn" border v-loading="loading" class="vg_cursor">
          <el-table-column label="材料编号" prop="mdrl_no" />
          <el-table-column label="材料图片" align="center">
            <template v-slot="scope">
              <el-image v-if="scope.row.imge_url" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"> </el-image>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="材料名称" prop="mdrl_name" />
          <el-table-column label="材料规格" prop="mdrl_spec" />
          <el-table-column label="材料类型" prop="mdrl_type" :formatter="formatLeavType" />
          <el-table-column label="材料单位" prop="mdrl_unit">
            <template v-slot="scope">
              <div v-if="scope.row.mdrl_unit">
                {{ scope.row.mdrl_unit }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mdrlAPI } from '@api/modules/mdrl';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';

export default {
  name: 'SmplEditBom',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mdrl_no: null,
        mdrl_name: null,
        mdrl_type: null,
        mdrl_color: null,
        colr_class: []
      },
      totalPage: 0,
      btn: {},
      loading: true,
      currentPage: 1,
      mdrlType: [
        { id: 1, label: '主材料' },
        { id: 2, label: '辅材料' },
        { id: 3, label: '其他' }
      ],
      title: '提示：相同数据仅限导入一条',
      optnColorList: []
    };
  },
  created() {
    this.initData();
  },
  filters: {
    formatMdrlWidth(row) {
      return helper.haveFour(row) + '米';
    },
    formatMdrlThick(row) {
      return helper.reservedDigits(row);
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  methods: {
    initData() {
      this.getMdrlsList();
    },
    // 获取材料信息
    getMdrlsList() {
      let searchBody = JSON.parse(JSON.stringify(this.searchForm));
      searchBody.page_no = this.currentPage;
      searchBody.status = 2;
      if (searchBody.colr_class.length > 0) {
        searchBody.colr_class = searchBody.colr_class.join(',');
      }
      get(mdrlAPI.getMdrls, searchBody).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loading = false;
          }, 300);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          setTimeout(() => {
            this.loading = false;
          }, 300);
        }
      });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getMdrlsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.currentPage = 1;
      this.getMdrlsList();
    },
    // 刷新
    getMdrlsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.currentPage = 1;
      (this.searchForm = {
        mdrl_no: null,
        mdrl_name: null,
        mdrl_type: null,
        mdrl_color: null,
        colr_class: []
      }),
        this.getMdrlsList();
    },
    // 跳转材料信息表
    goMdrl() {
      this.$emit('falseMdrl');
      this.jump(`/mdrl_list?perm_id=${this.helper.retPermId('mdrl')}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMdrlsList();
    },
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      return this.helper.picUrl(scope.row.imge_url, type, updateTime);
    },
    // 材料类型
    formatLeavType(row) {
      if (row.mdrl_type === 1) {
        return '钢材';
      } else if (row.mdrl_type === 2) {
        return '辅材';
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 取消选择
    cancel() {
      this.$emit('mdrlChanel', false);
    },
    // 确认选择
    confirmIn(val) {
      this.$emit('mdrlConfirm', val);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt {
  margin-top: 20px;
}
.vd_mar15 {
  margin: 15px 0;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis {
  display: flex;
}
</style>
