<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('mqcdForm')" @cancel="cancel" :stateFlag="false"></add-header>
    <el-form ref="mqcdForm" :model="mqcdForm" label-width="120px" size="mini">
      <el-row class="vd_mar15">
        <el-col :md="8">
          <el-form-item style="margin-bottom: 8px" label="材料编号" prop="mdrl_no" :rules="[{ required: true, message: ' ' }]">
            <el-input disabled maxlength="10" v-model="mqcdForm.mdrl_no" placeholder="请输入材料编号" show-word-limit>
              <template slot="append">
                <el-link type="primary" @click="mdrlImport">导入材料</el-link>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="材料名称" prop="mdrl_name">
            <el-input disabled v-model="mqcdForm.mdrl_name" show-word-limit placeholder="请输入材料名称" />
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="材料类型" prop="mdrl_type">
            <el-select disabled v-model="mqcdForm.mdrl_type" placeholder="请选择材料类型" clearable @change="mdrlTypeChange">
              <el-option v-for="item in mdrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="材料规格" prop="mdrl_height" v-if="mqcdForm.mdrl_type !== 1">
            <el-input disabled v-model="mqcdForm.mdrl_spec" show-word-limit placeholder="请输入材料规格"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="材料重量" prop="mdrl_weight">
            <el-input disabled v-model="mqcdForm.mdrl_weight" show-word-limit placeholder="请输入材料重量" />
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="材料材质" prop="mdrl_name">
            <el-input disabled v-model="mqcdForm.mdrl_qual" show-word-limit placeholder="请输入材料材质" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item style="margin-bottom: 8px" label="库位" prop="mqcd_bin" :rules="[{ required: true, message: ' ' }]">
            <el-select filterable v-model="mqcdForm.mqcd_bin" placeholder="请选择库位">
              <el-option v-for="item in binList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="数量" prop="mqcd_num" :rules="[{ required: true, message: ' ' }]">
            <el-input v-model="mqcdForm.mqcd_num" show-word-limit placeholder="请输入数量" />
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="安全库存" prop="mdrl_stock_limit">
            <el-input disabled v-model="mqcdForm.mdrl_stock_limit" show-word-limit placeholder="请输入安全库存" />
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="材料单价" prop="mdrl_price">
            <el-input disabled v-model="mqcdForm.mdrl_price" show-word-limit placeholder="请输入材料单价" />
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="材料不含税单价" prop="mdrl_price">
            <el-input disabled v-model="mqcdForm.mdrl_price" show-word-limit placeholder="请输入材料不含税单价" />
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="材料单位" prop="mdrl_price">
            <el-input disabled v-model="mqcdForm.mdrl_unit" show-word-limit placeholder="请输入材料单位" />
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item style="margin-bottom: 8px" label="供应商户" prop="supp_id">
            <el-select disabled v-model="mqcdForm.supp_id" placeholder="请选择供应商" clearable filterable @visible-change="suppChange">
              <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom: 8px" label="其他备注" prop="mqcd_remark">
            <el-input v-model="mqcdForm.mqcd_remark" type="textarea" :row="1" show-word-limit placeholder="请输入备注" />
          </el-form-item>
          <el-form-item label="材料图片" prop="imge_id">
            <el-upload
              disabled
              class="avatar-uploader"
              :action="uploadUrl"
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="mqcdForm.imge_id" :imgeUrl="mqcdForm.imge_url" :imgeUrlO="mqcdForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-dialog :title="title" :visible.sync="mdrlFlag" width="70%">
        <MqcdMtrlImport @falseMtrl="falseMtrl" @mdrlChanel="mdrlChanel" @mdrlConfirm="mdrlConfirm" ref="MqcdMtrlImport" />
      </el-dialog>
      <el-dialog :title="title1" :visible.sync="sourFlag" width="70%">
        <MqcdSourImport @falseSour="falseSour" @sourChanel="sourChanel" @sourConfirm="sourConfirm" ref="MqcdSourImport" />
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mqcdAPI } from '@api/modules/mqcd';
import { optnAPI } from '@api/modules/optn';
import { mdrlAPI } from '@api/modules/mdrl';
import { suppAPI } from '@api/modules/supp';
import { colrAPI } from '@/api/modules/colr.js';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import MqcdMtrlImport from '@/views/StockManagement/MqcdManage/TabChild/Componet/MqcdMtrlImport.vue';
import MqcdSourImport from '@/views/StockManagement/MqcdManage/TabChild/Componet/MqcdSourImport.vue';
import MqcdAddMtrl from '@/views/StockManagement/MqcdManage/TabChild/Componet/MqcdAddMtrl.vue';
import helper from '@assets/js/helper';
import { imgeAPI } from '@api/modules/imge';
import imgPopover from '@/views/component/imgPopover.vue';
// import MtrlImport from "./Componet/MdrlImport.vue"

export default {
  name: 'mqcdAddMain',
  components: {
    addHeader,
    inputUser,
    MqcdMtrlImport,
    MqcdSourImport,
    MqcdAddMtrl,
    imgPopover
  },
  created() {
    this.initData();
  },
  watch: {},
  data() {
    return {
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      mqcdForm: {
        mdrl_id: null,
        sour_mdrl_id: null,
        sour_no: null,
        mdrl_no: null,
        mdrl_name: null,
        mdrl_unit: null,
        mdrl_type: 0,
        mqcd_num: null,
        mqcd_bin: null,
        user_id: null,
        stff_id: null,
        dept_id: null,
        status: 0,
        mdrl_spec: '',
        mdrl_color: '',
        mdrl_weight: '',
        mdrl_width: null,
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        mdrl_thick: null,
        mdrl_comp: null,
        mdrl_stock_limit: 0,
        mdrl_prod_price: '',
        mdrl_remark: '',
        supp_id: '',
        colr_class: '',
        key: 0,
        belo_dept_id: null //所属部门
      },
      binList: [],
      mdrlType: [
        { id: 1, label: '主材料' },
        { id: 2, label: '辅材料' },
        { id: 3, label: '其他' }
      ],
      mdrlTypeGroupOpt: [],
      suppType: [],
      mdrlFlag: false,
      sourFlag: false,
      title: '材料信息',
      title1: '采购信息',
      option: [],
      binListCopy: [],
      disableMtrlAdd: true,
      disableMtrlType: true,
      disableMtrlColorClass: true,
      emptyDisable: false,
      optnColorList: []
    };
  },
  methods: {
    initData() {
      this.getMqcdType();
      this.getBinList();
      this.getDepartment();
      this.getSuppsList();
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1).then(res => {
        if (res.data.code === 0) {
          this.suppType = res.data.data;
        }
      });
    },
    // 商户下拉框下拉
    suppChange() {
      this.getSuppsList();
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 }).then(res => {
        if (res.data.code === 0) {
          this.binList = res.data.data;
          this.binListCopy = JSON.parse(JSON.stringify(res.data.data));
        }
      });
    },
    // 获取单位
    getMqcdType() {
      get(optnAPI.getAllContent, { perm_id: 10005 }).then(res => {
        if (res.data.code === 0) {
          this.mdrlTypeGroupOpt = res.data.data;
        }
      });
    },
    //获取打样部门
    getDepartment() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 }).then(res => {
        if (res.data.code === 0) {
          let arr = [];
          res.data.data.form.optn_cntt_list.forEach(item => {
            arr.push({
              value: item.param2,
              label: item.param1
            });
          });
          this.option = arr;
          return;
        }
        this.$message.error(res.data.msg);
      });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              if (this.disableMtrlAdd) {
                this.saveInfo();
              } else {
                this.saveInfoC();
              }
            } else {
              this.$message({ type: 'warning', message: '请填入必填信息' });
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let mqcdForm = {};
      let mqcdFormCopy = JSON.parse(JSON.stringify(this.mqcdForm));
      mqcdForm = Object.assign(mqcdFormCopy, staffForm);
      delete mqcdForm.status;
      if (mqcdForm.mdrl_type === 0) {
        mqcdForm.mdrl_no = mqcdForm.mdrl_no + mqcdForm.mdrl_color + 'C';
        mqcdForm.mdrl_color = mqcdForm.mdrl_color + 'C';
      } else {
        // mqcdForm.mdrl_no = mqcdForm.mdrl_no
        mqcdForm.mdrl_color = '';
      }
      post(mqcdAPI.addMqcd, mqcdForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          const permId = this.$route.query.perm_id;
          this.jump(`/mqcd_list?perm_id=${permId}`);
          this.resetForm('mqcdForm');
        } else if (res.data.code === 7) {
          this.$message({
            type: 'error',
            message: '库位中已有此材料'
          });
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
          this.initData();
        }
      });
    },
    saveInfoC() {
      const staffForm = this.$refs.userData.getData();
      let mqcdForm = {};
      let mqcdFormCopy = JSON.parse(JSON.stringify(this.mqcdForm));
      mqcdForm = Object.assign(mqcdFormCopy, staffForm);
      if (mqcdForm.mdrl_type === 0) {
        mqcdForm.mdrl_no = mqcdForm.mdrl_no + mqcdForm.mdrl_color + 'C';
        mqcdForm.mdrl_color = mqcdForm.mdrl_color + 'C';
      } else {
        mqcdForm.mdrl_color = '';
      }
      mqcdForm.mdrl_id = null;
      post(mqcdAPI.addMtrlToMqcd, mqcdForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump(`/mqcd_list?perm_id=${permId}`);
            this.resetForm('mqcdForm');
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '库位中已有此材料'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('mqcdForm');
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 新增字表数据
    addRow() {
      let temp = {};
      if (this.mqcdForm.supp_id) {
        temp.supp_id = this.mqcdForm.supp_id;
      } else {
        temp.supp_id = null;
      }
      if (this.mqcdForm.mdrl_prod_price) {
        temp.mdrl_prod_price = this.mqcdForm.mdrl_prod_price;
      } else {
        temp.mdrl_prod_price = '0.0000';
      }
      temp.mdrl_info_checked = false;
      temp.key = this.mqcdForm.mdrl_info_list.length;
      this.mqcdForm.mdrl_info_list.push(temp);
    },
    // 材料类型变化
    mdrlTypeChange(value) {
      this.mqcdForm.mdrl_name = null;
      if (value === 0) {
        this.disableMtrlType = true;
      } else {
        this.disableMtrlType = false;
        this.mqcdForm.mdrl_color = '';
      }
    },
    // 保留位数
    baretaino() {
      this.mqcdForm.mdrl_no = this.mqcdForm.mdrl_no.substr(0, 2);
      this.mqcdForm.mdrl_thick = this.helper.reservedDigits(this.mqcdForm.mdrl_thick);
      this.mqcdForm.mdrl_width = this.helper.reservedDigits(this.mqcdForm.mdrl_width);
      this.mqcdForm.mdrl_prod_price = this.helper.haveFour(this.mqcdForm.mdrl_prod_price);
      this.mqcdForm.mdrl_weight = this.helper.reservedDigits(this.mqcdForm.mdrl_weight);
      this.mqcdForm.mdrl_color = this.mqcdForm.mdrl_color.substring(0, this.mqcdForm.mdrl_color.indexOf('C'));
    },
    // 调用匹配接口
    getMtrlByInfo() {
      let params = {};
      params.mdrl_type = this.mqcdForm.mdrl_type;
      params.mdrl_no = this.mqcdForm.mdrl_no;
      if (this.mqcdForm.mdrl_type === 0) {
        params.mdrl_width = this.mqcdForm.mdrl_width;
        params.mdrl_color = this.mqcdForm.mdrl_color + 'C';
        params.mdrl_thick = this.mqcdForm.mdrl_thick;
        params.mdrl_weight = this.mqcdForm.mdrl_weight;
      } else {
        params.mdrl_name = this.mqcdForm.mdrl_name;
        params.mdrl_spec = this.mqcdForm.mdrl_spec;
      }
      get(mdrlAPI.getMtrlByInfo, params)
        .then(res => {
          if (res.data.code === 0) {
            let form = res.data.data.form;
            this.mqcdForm = Object.assign(this.mqcdForm, form);
            let urlEng = JSON.parse(JSON.stringify(this.mqcdForm.imge_url));
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
            this.mqcdForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
            this.mqcdForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            if (!form.colr_class) {
              this.disableMtrlColorClass = false;
            } else {
              this.disableMtrlColorClass = true;
            }
            this.baretaino();
            this.disableMtrlAdd = true;
            this.disableMtrlType = true;
            this.emptyDisable = true;
          } else if (res.data.code === 2) {
            let mg = '该材料未存在，请把内容补充完整保存添加';
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.disableMtrlAdd = false;
            if (this.mqcdForm.mdrl_type === 0) {
              this.disableMtrlType = false;
            } else {
              this.disableMtrlType = true;
            }
            this.emptyDisable = true;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 匹配
    matchingMqcd() {
      if (this.mqcdForm.mdrl_type === 0) {
        if (
          this.mqcdForm.mdrl_no &&
          this.mqcdForm.mdrl_width &&
          this.mqcdForm.mdrl_thick &&
          this.mqcdForm.mdrl_color &&
          this.mqcdForm.mdrl_weight
        ) {
          this.getMtrlByInfo();
        } else {
          this.$message.warning('请把材料编号、材料门幅、材料毛高、材料克重和潘通色号补充完整');
        }
      } else {
        if (this.mqcdForm.mdrl_no && this.mqcdForm.mdrl_name && this.mqcdForm.mdrl_spec) {
          this.getMtrlByInfo();
        } else {
          this.$message.warning('请把材料编号、材料名称和材料规格补充完整');
        }
      }
    },
    // 清空
    emptyMqcd() {
      this.resetForm('mqcdForm');
      this.emptyDisable = false;
    },
    // 材料导入
    mdrlImport() {
      this.mdrlFlag = true;
    },
    // 材料导入取消
    mdrlChanel() {
      this.mdrlFlag = false;
    },
    falseMtrl() {
      this.mdrlChanel();
    },

    // 材料导入确认
    mdrlConfirm(val) {
      console.log(val);
      let value = JSON.parse(JSON.stringify(val));
      this.mqcdForm = Object.assign(this.mqcdForm, value);
      this.mqcdForm.sour_no = null;
      let urlEng = JSON.parse(JSON.stringify(this.mqcdForm.imge_url));
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      this.mqcdForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
      this.mqcdForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      this.emptyDisable = true;
      this.mdrlFlag = false;
    },
    // 采购材料明细导入
    sourImport() {
      this.sourFlag = true;
    },
    // 采购材料取消
    sourChanel() {
      this.sourFlag = false;
    },
    falseSour() {
      this.sourChanel();
    },
    // 采购材料明细导入确认
    sourConfirm(val) {
      let value = JSON.parse(JSON.stringify(val));
      this.mqcdForm = Object.assign(this.mqcdForm, value);
      let urlEng = JSON.parse(JSON.stringify(this.mqcdForm.imge_url));
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      this.mqcdForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
      this.mqcdForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      if (!this.mqcdForm.colr_class) {
        this.disableMtrlColorClass = false;
      } else {
        this.disableMtrlColorClass = true;
      }
      this.baretaino();
      this.emptyDisable = true;
      this.mdrlFlag = false;
      this.sourFlag = false;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mqcdForm.imge_url = null;
      this.mqcdForm.imge_urlO = null;
      this.mqcdForm.imge_id = null;
      this.disableMtrlAdd = true;
      this.disableMtrlType = true;
      this.emptyDisable = false;
    },
    mdrlColorInput() {
      this.mqcdForm.mdrl_color = this.helper.keepTNum(this.mqcdForm.mdrl_color);
      this.mqcdForm.mdrl_color = this.mqcdForm.mdrl_color.substr(0, 4);
    },
    // 材料编号失去焦点匹配颜色大类
    changeMtrlColor() {
      if (this.mqcdForm.mdrl_type === 0 && this.mqcdForm.mdrl_color.length !== 0) {
        this.clickSyncColor();
      }
    },
    //同步颜色 大类
    clickSyncColor() {
      get(colrAPI.getColrClassByPantone, {
        colr_pantone: this.mqcdForm.mdrl_color + 'C'
      })
        .then(res => {
          if (res.data.code === 0) {
            this.mqcdForm.colr_class = res.data.data.form.colr_class;
            this.disableMtrlColorClass = true;
            //  this.$message.success('同步'+res.data.msg)
            return;
          } else if (res.data.code === 2) {
            this.mqcdForm.colr_class = null;
            this.$message.warning('未匹配到该颜色大类');
            this.disableMtrlColorClass = false;
            return;
          }
          this.$message.warning(res.data.msg);
        })
        .catch(res => {
          this.$message.warning(res.data.msg);
        });
    },
    // 材料编号截取
    mdrlNoInput() {
      this.mqcdForm.mdrl_no = this.helper.keepEnglish(this.mqcdForm.mdrl_no).toUpperCase();
      this.mqcdForm.mdrl_no = this.mqcdForm.mdrl_no.substr(0, 2);
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        if (this.imgTypeFlag) return this.$message.warning('仅支持jpg、png、jpeg格式的图片');
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.mqcdForm.imge_id = res.data.imge_id;
        this.mqcdForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.mqcdForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      this.imgTypeFlag = false;
      let str = res.name.substr(res.name.indexOf('.') + 1, res.name.length);
      let imgTypeList = ['jpg', 'png', 'jpeg'];
      if (imgTypeList.indexOf(str) === -1) this.imgTypeFlag = true;
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.color_span {
  display: inline-block;
  width: 40px;
  height: 100%;
  border-right: #dcdfe6 1px solid;
  margin-right: 10px;
  box-sizing: border-box;
}
.but_col {
  width: 300px;
  padding-left: 100px;
  margin: 5px 0;
}
.vd_hidden {
  visibility: hidden;
}
</style>
